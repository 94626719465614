import classNames from "classnames";
import { useCallback } from "react";
import { Close, Popup } from "../../components";
import { useI18n, useSizeSelector } from "../../hooks";
export const SizePopup = ({ sizes, setSku, setSize, popupShow, setPopupShow, FasletBtn, }) => {
    const { $t } = useI18n();
    const { fullSizes, selectedSize, setSelectedSize } = useSizeSelector({
        sizes,
        setSku,
    });
    const clear = useCallback(() => {
        setSize("");
        setSku("");
        setSelectedSize("");
    }, [setSelectedSize, setSize, setSku]);
    return (<Popup isVisible={popupShow} containerCls="right-0 left-0 h-1/2 lg:w-full lg:!bottom-0 lg:max-h-[400px] overflow-y-auto lg:h-auto lg:mx-auto" initCls="bottom-0 opacity-100 lg:bottom-[40%]" endCls="-bottom-[40%] opacity-0 lg:bottom-[40%] lg:opacity-100" close={() => {
            setPopupShow(false);
            clear();
        }}>
            {FasletBtn}
            <div className="relative flex h-full flex-col overflow-auto border-t border-t-gray-disabled bg-white lg:border lg:border-gray-disabled">
                <div className="sticky top-0 bg-white py-5 px-8 text-sm font-medium">
                    <div className="flex justify-between">
                        <span>{$t("Select your size")}</span>
                        <span className="cursor-pointer lg:hidden" onClick={() => {
            setPopupShow(false);
            clear();
        }}>
                            <Close width={24} height={24} className="scale-75"/>
                        </span>
                    </div>
                </div>
                <div className="flex flex-col pb-4">
                    {fullSizes.map(({ size, isOnStock, sku }) => {
            return (<div key={size} onClick={() => {
                    if (isOnStock) {
                        setSelectedSize(size);
                        setSize(size);
                        setSku(sku);
                        setPopupShow(false);
                    }
                }} className={classNames("flex cursor-pointer justify-between py-3 px-8", isOnStock ? "text-black" : "text-gray-disabled", selectedSize === size ? "bg-gray-disabled" : "bg-white")}>
                                <span>{size}</span>
                                {!isOnStock && <span>{$t("Out of stock")}</span>}
                            </div>);
        })}
                </div>
            </div>
        </Popup>);
};
