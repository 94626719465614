import { GRichText } from "@/components";
import { useI18n } from "@jog/react-components";
const PaymentInfoHeader = ({ paymentInfoTexts, orderNumber, name }) => {
    const { $t } = useI18n();
    return (<div>
            <h1 className="my-5 font-regular text-lg font-bold uppercase">{name}</h1>
            <div className="grid grid-cols-12">
                <div className="col-span-12 text-base">
                    <div className="pb-1.25 font-regular text-sm font-normal">
                        {$t("We have received your order. the number of your order is")}{" "}
                        <span className="font-primary font-normal">{orderNumber}</span>
                        .&nbsp;
                        {$t("Confirmation has also been sent to your email address.")}
                    </div>
                    <GRichText field={paymentInfoTexts}/>
                </div>
            </div>
        </div>);
};
export default PaymentInfoHeader;
