import { ErrorPage } from "@/components/ErrorPage/ErrorPage";
import { CampaignPopup } from "@/containers/CampaignPopup/CampaignPopup";
import { CountryRedirectModal } from "@/containers/CountryRedirectModal";
import { theme } from "@/layout/theme";
import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { fetchPopup } from "@/lib/prismicio/popup";
import { useEmailValidation } from "@/lib/prismicio/useEmailValidation";
import { useWishlist } from "@/lib/store/wishlist";
import { AddedProductProvider, I18nCtx, LayoutErrorBoundary, PopupProvider, SidebarProvider, ValidateEmail, useAdpage, useTwProfileKey, } from "@jog/react-components";
import { gtmAddUserId, gtmAdpageUserData, gtmPageTracking, localStorage, lowerCaseLocale, setCountryRedirectModalDisplayTime, shouldShowCountryRedirectModal, shouldShowPopupModal, } from "@jog/shared";
import { ThemeProvider, withTheme } from "@jog/theming";
import { AlertBox, Header, Modal, TopBanner } from "components";
import { Footer, SocialSection } from "containers";
import { observer } from "mobx-react";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useStores } from "stores";
const reCaptchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_CLIENT_KEY;
const LayoutError = () => <ErrorPage locale={lowerCaseLocale()}/>;
export function Layout({ children, isCheckoutPage = false, isSocialSectionVisible = true, isTopBannerVisible = true, gtmTypeOfPage = "", isCartPage = false, }) {
    const [showCountryRedirectModal, setShowCountryRedirectModal] = useState(false);
    const [showPopupModal, setShowPopupModal] = useState(false);
    const { userStore } = useStores();
    const router = useRouter();
    useTwProfileKey();
    useEffect(() => {
        const navigationLanguage = localStorage.getItem("navigationLanguage");
        const lang = process.env.NEXT_PUBLIC_LANG;
        if (!navigationLanguage || navigationLanguage !== lang) {
            localStorage.setItem("navigationLanguage", lang);
        }
    }, []);
    useEffect(() => {
        router.isReady && gtmPageTracking(gtmTypeOfPage);
    }, [gtmTypeOfPage, router]);
    const adpage = useAdpage();
    useEffect(() => {
        router.isReady && (adpage === null || adpage === void 0 ? void 0 : adpage.marketing) && (adpage === null || adpage === void 0 ? void 0 : adpage.device) && gtmAdpageUserData(adpage, gtmTypeOfPage);
    }, [adpage, gtmTypeOfPage, router.asPath, router.isReady]);
    useEffect(() => {
        const userId = localStorage.getItem("user-id");
        gtmAddUserId(userId);
    }, []);
    useEffect(() => {
        if (userStore.userCountry === "") {
            userStore.getCountry().then((userCountry) => {
                if (userCountry) {
                    setShowCountryRedirectModal(shouldShowCountryRedirectModal(userCountry));
                    setShowPopupModal(shouldShowPopupModal());
                }
            });
        }
        else {
            setShowCountryRedirectModal(shouldShowCountryRedirectModal(userStore.userCountry));
            setShowPopupModal(shouldShowPopupModal());
        }
    }, [userStore]);
    const toggleCountryRedirectModal = () => {
        setShowCountryRedirectModal(!showCountryRedirectModal);
        setCountryRedirectModalDisplayTime();
    };
    const newsletterStorage = localStorage.getItem("newsletter");
    const layout = useLayout();
    const validateEmailList = useEmailValidation();
    const wishlistError = useWishlist.use.error();
    const wishlist = useWishlist.use.initWishlist();
    useEffect(() => {
        wishlist();
    }, [wishlist]);
    return (<AddedProductProvider closeTime={(layout === null || layout === void 0 ? void 0 : layout.layout.cartPopupShowTime) || 5000}>
            <PopupProvider fetch={fetchPopup}>
                <I18nCtx.Provider value={{ translations: (layout === null || layout === void 0 ? void 0 : layout.translations) || {} }}>
                    <ValidateEmail.Provider value={validateEmailList}>
                        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} language={process.env.NEXT_PUBLIC_LANG}>
                            <ThemeProvider theme={theme}>
                                <SidebarProvider>
                                    <Header isCartPage={isCartPage} isCheckoutPage={isCheckoutPage}/>
                                </SidebarProvider>
                                {isTopBannerVisible && <TopBanner />}
                                <LayoutErrorBoundary Fallback={LayoutError}>{children}</LayoutErrorBoundary>
                                {isSocialSectionVisible && <SocialSection />}
                                <Footer />
                                {showCountryRedirectModal && (<CountryRedirectModal hideModal={toggleCountryRedirectModal}/>)}
                                {!showCountryRedirectModal && showPopupModal && !(newsletterStorage === null || newsletterStorage === void 0 ? void 0 : newsletterStorage.isSubscribed) && (<CampaignPopup />)}

                                {wishlistError && (<Modal>
                                        <AlertBox isError text={wishlistError.message}/>
                                    </Modal>)}
                            </ThemeProvider>
                        </GoogleReCaptchaProvider>
                    </ValidateEmail.Provider>
                </I18nCtx.Provider>
            </PopupProvider>
        </AddedProductProvider>);
}
export default withTheme(observer(Layout));
