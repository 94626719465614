import PrimaryButton from "@/components/PrimaryButton/PrimaryButton";
import { useMyAccountData } from "@/hooks/useMyAccountData";
import { FormikInputField, InputWrapper, Link, useI18n } from "@jog/react-components";
import { passwordValidation } from "@jog/shared";
import { FormikFormWrapper } from "@jog/theming";
import { AlertBox, LogoLoader, Section } from "components";
import { Form, Formik } from "formik";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useStores } from "stores";
const UpdatePassword = () => {
    const { userStore } = useStores();
    const { changePassword } = useMyAccountData();
    const [isBtnSubmitting, setIsBtnSubmitting] = useState(false);
    let timer = null;
    useEffect(() => {
        return () => clearTimeout(timer);
    }, [timer]);
    const handleSubmit = (values) => {
        userStore.removeMyAccountAlerts();
        setIsBtnSubmitting(true);
        changePassword(values.currentPassword, values.newPassword);
        window.scrollTo({ top: 0, behavior: "smooth" });
        timer = setTimeout(() => {
            setIsBtnSubmitting(false);
        }, 6000);
    };
    const handleCancel = () => {
        userStore.removeMyAccountAlerts();
    };
    const { $t } = useI18n();
    return (<Section title={$t("Password")}>
            {userStore.isChangePasswordSuccess && <AlertBox text={$t("Password changed")}/>}
            {userStore.isChangePasswordError && (<AlertBox isError={true} text={$t("Something went wrong please try again")}/>)}
            {isBtnSubmitting && <LogoLoader />}
            <Formik initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
        }} validate={(values) => {
            const errors = {};
            if (!passwordValidation(values.currentPassword)) {
                errors.currentPassword = $t("Minimum 6 characters");
            }
            if (!passwordValidation(values.newPassword)) {
                errors.newPassword = $t("Minimum 6 characters");
            }
            if (!passwordValidation(values.confirmNewPassword)) {
                errors.confirmNewPassword = $t("Minimum 6 characters");
            }
            else if (values.newPassword !== values.confirmNewPassword) {
                errors.confirmNewPassword = $t("Password does not match");
            }
            return errors;
        }} onSubmit={handleSubmit}>
                {() => (<Form>
                        <FormikFormWrapper className="-mx-2 mt-7.5">
                            <div className="flex flex-wrap">
                                <div className="basis-full md:basis-8/12">
                                    <InputWrapper>
                                        <FormikInputField id="currentPassword" fieldName="currentPassword" type="password" label={$t("Current password *")}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="newPassword" fieldName="newPassword" type="password" label={$t("New password *")}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="confirmNewPassword" fieldName="confirmNewPassword" type="password" label={$t("Confirm new Password *")}/>
                                    </InputWrapper>

                                    <div className="mt-5 flex flex-wrap gap-2 px-2 lg:gap-0">
                                        <Link href="/my-account/profile">
                                            <PrimaryButton className="!bg-gray-shuttle !text-white hover:bg-auto" onClickFunction={handleCancel}>
                                                {$t("Cancel")}
                                            </PrimaryButton>
                                        </Link>
                                        <PrimaryButton className="!bg-black !text-white hover:bg-auto" buttonType="submit" disabled={isBtnSubmitting}>
                                            {$t("Update")}
                                        </PrimaryButton>
                                    </div>
                                </div>
                            </div>
                        </FormikFormWrapper>
                    </Form>)}
            </Formik>
        </Section>);
};
export default observer(UpdatePassword);
