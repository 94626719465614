import { AlertBox } from "@/components/AlertBox/AlertBox";
import { Button } from "@/components/Button/Button";
import { Input } from "@/components/InputText/Input";
import { LogoLoader } from "@/components/LogoLoader/LogoLoader";
import { Modal } from "@/components/Modal/Modal";
import campaign from "@/lib/prismicio/prismic-campaign.json";
import { CampaignContent, Close, NewsLetterFormBase, useCampaignPopup, usePopup } from "@jog/react-components";
import { lowerCaseLocale, postNewsletter } from "@jog/shared";
import { useMemo } from "react";
import ThankyouMessage from "./ThankyouMessage";
const NewsLetterForm = NewsLetterFormBase({
    Input,
    Button,
    AlertBox,
    LogoLoader,
});
export const CampaignPopup = () => {
    const { popup } = usePopup();
    const newsletterUrl = useMemo(() => { var _a; return ((_a = campaign === null || campaign === void 0 ? void 0 : campaign[lowerCaseLocale()]) === null || _a === void 0 ? void 0 : _a.popupCampaign) || ""; }, []);
    const { togglePopupModal, showThankyouMessage, setShowThankyouMessage, display } = useCampaignPopup();
    if (!display || !popup)
        return <></>;
    return (<Modal hideModal={togglePopupModal} height="auto" backdropDismiss={false} isSecondlyClose={true} SecondlyClose={({ onClick }) => (<span className="mb-3 cursor-pointer" onClick={onClick}>
                    <Close color={popup.closeIconColor || "white"} className="stroke-cap-round h-3 w-3 stroke-white stroke-[30px] xs:h-6 xs:w-6 lg:mr-6 lg:mb-2" style={{
                stroke: popup.closeIconColor || "white",
            }}/>
                </span>)} modalClass="!w-full lg:!w-[710px] !min-w-full sm:!min-w-[300px]" headerClass="lg:translate-y-[45px]" contentClass="!overflow-y-visible !overflow-x-visible" background={{
            desktop: "transparent",
            mobile: "transparent",
        }}>
            <CampaignContent newsLetterForm={<NewsLetterForm apis={{ postNewsletter }} hideModal={togglePopupModal} newsletterUrl={newsletterUrl} source="popup" afterSubmit={() => setShowThankyouMessage(true)}/>} thankyouMessage={<ThankyouMessage thankyouTitle={popup.campaignPopupThankyouTitle} hideModal={togglePopupModal} thankyouBody={popup.campaignPopupThankyouBody}/>} showThankyouMessage={showThankyouMessage}/>
        </Modal>);
};
