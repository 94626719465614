import { NextImage, useI18n } from "@jog/react-components";
import { getCountryNameByCode } from "@jog/shared";
import { asImageSrc, asText, isFilled } from "@prismicio/helpers";
const UserInfo = ({ order, paymentIcons, }) => {
    const { $t } = useI18n();
    const shippingAddress = order.shippingAddress;
    const icons = order.paymentInfo.payments
        .filter((payment) => {
        var _a, _b, _c, _d;
        return ((_b = (_a = payment.obj) === null || _a === void 0 ? void 0 : _a.paymentMethodInfo) === null || _b === void 0 ? void 0 : _b.method) === "Giftcard" ||
            ((_d = (_c = payment.obj) === null || _c === void 0 ? void 0 : _c.paymentStatus) === null || _d === void 0 ? void 0 : _d.interfaceText) === "Success";
    })
        .map((payment) => payment.obj.paymentMethodInfo.method)
        .map((payment) => paymentIcons.filter((el) => el.match_key.toLowerCase() === payment.toLowerCase()))
        .map((payment) => payment.at(0))
        .filter(Boolean);
    return (<div className="border-y border-gray-lightSand px-0 pt-5 pb-10 text-sm">
            <div className="grid grid-cols-2 gap-4">
                <div className="col-span-1">
                    <h4 className="font-regular text-lg font-bold uppercase">{$t("data + delivery address")}</h4>
                    <div className="pb-1.25">
                        {shippingAddress.firstName} {shippingAddress.lastName}
                    </div>
                    <div className="pb-1.25">
                        {shippingAddress.streetName} {shippingAddress.streetNumber}{" "}
                        {shippingAddress.additionalStreetInfo}
                    </div>
                    <div className="pb-1.25">
                        {shippingAddress.postalCode} {shippingAddress.city}
                    </div>
                    <div className="pb-1.25">{$t(getCountryNameByCode(shippingAddress.country))}</div>
                </div>
                <div className="col-span-1">
                    <div className="mb-2.5 font-primary text-base font-medium">{$t("Payment method")}</div>
                    {icons.map((item, index) => (<div className="mb-2.5 font-primary text-base font-medium" key={index}>
                            {asText(item.payment_icon_text)}
                            {isFilled.image(item.payment_image) && (<NextImage blur={false} src={asImageSrc(item.payment_image)} alt="Payment Icon" className="ml-5 inline h-7.5 w-auto" width={(item.payment_image.dimensions.width / item.payment_image.dimensions.height) *
                    30} height={30}/>)}
                        </div>))}
                </div>
            </div>
        </div>);
};
export default UserInfo;
